/*--------------------------
    One Page Style Area  width: 1170px; margin-left: 320px;
---------------------------*/


.rn-nft-mid-wrapper {
    padding-left: 320px;
    padding-right: 420px;

    @media #{$laptop-device} {
        padding-left: 320px;
        padding-right: 30px;
    }

    @media #{$lg-layout} {
        padding-left: 320px;
        padding-right: 30px;
    }

    @media #{$md-layout} {
        padding-left: 30px;
        padding-right: 30px;
    }

    @media #{$sm-layout} {
        padding-left: 15px;
        padding-right: 15px;
    }
}



/*--------------------------
    Header Left Sidebar
----------------------------*/
.left-header-style {
    color: white;
    position: fixed;
    left: 0px;
    overflow-y: auto;
    overflow-x: hidden;
    height: 100vh;
    min-height: 100vh;
    top: 0px;
    width: 290px;
    z-index: 1;
    transform: translateX(0px);
    background: var(--background-color-1);
    padding: 50px 40px 40px;
    transition: all 0.3s ease-in-out 0s;
    flex-direction: column;
    justify-content: space-between;

    .logo-area {
        margin-bottom: 50px;
    }

    .mainmenu-nav {
        margin-top: 0;

        .mainmenu {
            margin-top: 30px;
            flex-direction: column;
            list-style: none;
            padding: 0;

            li {
                a {
                    display: flex;
                    padding: 10px 0;
                    margin: 0;
                    position: relative;
                    align-items: center;
                    transition: var(--transition);

                    &::after {
                        position: absolute;
                        content: '';
                        right: 0;
                        width: 2px;
                        height: 50%;
                        background: var(--color-primary);
                        top: 43%;
                        transform: translateY(-50%);
                        opacity: 0;
                        transition: var(--transition);
                    }

                    svg {
                        margin-right: 15px;
                        padding: 7px;
                        background: var(--color-primary-alta);
                        border-radius: 4px;
                        color: var(--color-white);
                        width: 29px;
                        height: auto;
                        transition: var(--transition);
                    }
                    &.active ,
                    &:hover,
                    &:focus {
                        color: var(--color-primary);
                        svg {
                            background: var(--color-primary) !important;
                            color: var(--color-white) !important;
                        }
                        &::after {
                            opacity: 1;
                        }
                    }
                }
            }
        }
    }

    .authore-profile {
        text-align: center;
        padding: 18px;
        background: var(--background-color-1);
        border-radius: 5px;

        .thumbnail {
            margin-top: -50px;
            margin-bottom: 8px;
            position: relative;
            &::after {
                position: absolute;
                right: 57px;
                content: '';
                top: 70%;
                background: #31a24c;
                height: 12px;
                width: 12px;
                z-index: 5;
                border-radius: 50%;
            }
            img {
                border-radius: 50%;
                max-width: 66px;
                border: 3px solid var(--color-border);
            }
        }
        .au-content {
            p {
                &.name {
                    margin-bottom: 4px;
                    color: var(--color-light);
                    font-weight: 500;
                }
            }
            p {
                &.blc {
                    font-size: 14px;
                    font-weight: 400;
                    margin-bottom: 0;
                    span {
                        &.value {
                            color: var(--color-primary);
                        }
                    }
                }
            }
        }
    }

    .sidebar-nav-wrapper {
        min-height: 500px;
    }

    &::-webkit-scrollbar {
        width: 5px;
        background: var(--color-primary-alta);
    }

    &::-webkit-scrollbar-thumb {
        background: var(--color-primary);
        border-radius: 5px;
    }
}





.rn-top-bar-area {
    display: flex;
    justify-content: space-between;
    padding: 25px 0;
    align-items: center;
    position: relative;
    @media #{$md-layout} {
        justify-content: center;
    }
    @media #{$sm-layout} {
        justify-content: center;
    }
   
    .input-group {
        @media #{$large-mobile} {
            margin: 0 auto;
            margin-bottom: 15px;
        }
        input {
            background: #3a3a5157;
            color: var(--color-light);
            border-radius: 5px 0 0 5px;
            max-width: 220px;
            font-size: 14px;
            &:focus {
                box-shadow: none;
            }
        }
        .input-group-append {
            button {
                &.btn {
                    border-radius: 0 5px 5px 0;

                    svg {
                        width: 18px;
                    }
                }
            }
        }
    }
    .contact-area {
        display: flex;
        align-items: center;
        margin: -5px;
        @media #{$sm-layout} {
            flex-wrap: wrap;
        }
        @media #{$large-mobile} {
            justify-content: center;
        }
    }
    .setting-option {
        margin: 5px;
        height: auto;
        .icon-box {
            > a,
            > button {
                background: var(--color-primary-alta);
                border-radius: 5px;
                transition: var(--transition);
                border: 0 none;
                width: 40px;
                display: inline-block;
                height: 40px;
                line-height: 43px;
                text-align: center;
                margin: 0;

                &.btn {
                    padding: 0 20px;
                    width: auto;
                }
                i {
                    color: var(--color-heading);
                }
                img {
                    object-fit: contain;
                    width: 100%;
                    height: 100%;
                }
                
                &:hover {
                    background: var(--color-primary);
                }
            }
        }
    }

    .my_switcher {
        background: var(--color-primary-alta);
        border: 0 none;
        border-radius: 5px;
        ul {
            li {
                a {
                    background: transparent;
                    img {
                        filter: brightness(1);
                    }
                }
            }
        }
    }
}





