.rn-address {
    padding: 40px;
    border-radius: 10px;
    padding-top: 40px;
    background: var(--background-color-1);
    padding-bottom: 50px;
    transition: 0.3s;
    border: 1px solid var(--color-border);
    .icon{
        color: var(--color-primary);
        border-radius: 100%;
        font-size: 33px;
        display: inline-block;
        margin-bottom: 12px;
        svg{
            height: 40px;
            width: 40px;
        }
    }
    .inner{
        h4{
            &.title{
                font-size: 20px;
                font-weight: 600;
                letter-spacing: 1px;
            }
        }
        p{
            margin: 0;
            a{
                margin: 0;
                color: var(--color-light);
            }
        }
    }
}