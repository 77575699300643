.right-post-details {
    height: 100%;

    .widge-wrapper {
        position: sticky;
        z-index: 10;
    }

}

.rn-blog-details-default {
    .blog-content-top {
        margin-bottom: 30px;
    }

    .date {
        display: inline-block;
        margin-bottom: 10px;
    }

    .bd-thumbnail {
        img {
            border-radius: 6px;
        }
    }
}

// Comment Form Styles 

.rn-comment-form {
    .section-title {
        span {
            &.subtitle {
                font-weight: 600;
                letter-spacing: 0.5px;
                line-height: 18px;
                font-size: 16px;
                border-radius: 4px;
                margin-bottom: 12px;
            }
        }
    }
}

iframe {
    border-radius: 10px;
}

.rnform-group {
    margin-bottom: 20px;

    input {
        border: 2px solid var(--color-border);
        border-radius: 4px;
        height: 50px;
        font-size: 16px;
    }
}

.rn-comment-form .inner .rnform-group textarea {
    min-height: 193px;
    border: 2px solid var(--color-border);
    border-radius: 4px;
    resize: none;
    padding: 15px;
    font-size: 16px;
}

// comment style
.comment-list {
    list-style: none;
    padding: 0;
    margin: 0;

    .comment {
        padding: 0;
        margin: 0;

        &.byuser {
            .single-comment {
                background-color: var(--color-primary-alta) !important;
                border: 1px solid var(--color-border) !important;
            }
        }

        .single-comment {
            padding: 40px 40px;
            margin-bottom: 40px;
            border-radius: 4px;
            background-color: var(--background-color-1);
            border: 1px solid var(--color-border);

            @media #{$sm-layout} {
                padding: 30px 20px;
            }
        }

        .comment-img {
            display: flex;
            align-items: center;
            padding: 0;
            margin-bottom: 18px;

            .comment-avatar {
                margin-right: 20px;
                border-radius: 3px;
            }

            .commenter {
                color: var(--color-heading);
                display: block;
                text-decoration: none;
                font-size: 17px;
                line-height: 24px;
                font-weight: 600;
                margin-bottom: 6px;
            }

            .time-spent {
                font-size: 13px;
                color: var(--color-body);
                font-weight: 400;
            }
        }

        .comment-text {
            font-size: 16px;
            line-height: 1.7;
            font-weight: 400;

            p {
                font-size: 16px;
                line-height: 1.7;
                font-weight: 400;
            }
        }

        .reply-edit {
            display: flex;
            font-weight: 500;
            margin-top: 15px;

            .reply {
                a {
                    &.comment-reply-link {
                        color: var(--color-primary);
                        transition: 0.3s;
                        font-size: 16px;

                        i {
                            padding-right: 4px;
                        }
                    }
                }
            }
        }
    }

    ul {
        &.children {
            list-style: none;
            padding-left: 80px;

            @media #{$sm-layout} {
                padding-left: 0px;
            }

            .comment {
                position: relative;

                &::before {
                    content: '';
                    position: absolute;
                    top: 0;
                    height: 100%;
                    border-left: 1px solid var(--color-border);
                    left: -45px;

                    @media #{$sm-layout} {
                        display: none;
                    }
                }

                .single-comment {
                    position: relative;

                    &::before {
                        content: '';
                        position: absolute;
                        top: 50%;
                        border-top: 1px solid #212e48;
                        left: -45px;
                        width: 45px;

                        @media #{$sm-layout} {
                            display: none;
                        }
                    }
                }
            }
        }
    }
}