.rn-top-top-seller-area {
    h3 {
        &.title {
            margin-right: 10px;
        }
    }
}

.nice-selector-transparent {
    .nice-select {
        background: transparent;
        @media #{$md-layout} {
            position: relative;
            top: -6px;
        }
        @media #{$sm-layout} {
            position: relative;
            top: -8px;
        }
        span {
            font-size: 32px;
            font-weight: 600;
            color: var(--color-primary);

            @media #{$sm-layout} {
                font-size: 24px;
            }
        }
        .list {
            @media #{$sm-layout} {
                left: auto;
                right: 0;
            }
        }
        &::after {
            border-bottom: 2px solid var(--color-primary);
            border-right: 2px solid var(--color-primary);
            right: 3px;
            height: 10px;
            width: 10px;
            top: 47%;
        }
    }
}

.justify-sm-center {
    @media #{$small-mobile} {
        justify-content: center;
    }
}

.top-seller-inner-one {
    .top-seller-wrapper {
        display: flex;
        align-items: center;
        @media #{$small-mobile} {
            flex-direction: column;
            text-align: center;
        }
        .thumbnail {
            max-width: 60px;
            @media #{$small-mobile} {
                margin-bottom: 5px;
            }
            a {
                display: block;
                border-radius: 50%;
                border: 3px solid var(--color-border);
                img {
                    overflow: hidden;
                    transition: 0.3s;
                    border-radius: 100%;
                }
            }
        }
        .top-seller-content {
            margin-left: 12px;

            @media #{$small-mobile} {
                margin-left: 0;
            }

            a {

                h6 {
                    margin-bottom: 0;
                    font-size: 20px;
                    font-weight: 500;
                    transition: var(--transition);
                    @media #{$small-mobile} {
                        font-size: 16px;
                        margin-top: 10px;
                    }
                    &:hover {
                        color: var(--color-primary);
                    }
                }
            }
            span {
                font-size: 14px;
                &.count-number {
                    display: block;
                }
                a {
                    margin-left: 7px;
                    text-transform: capitalize;
                }
            }
        }
        

        &:hover {
            .thumbnail {
                a {
                    img {
                        transform: scale(1.2);
                    }
                }
            }
        }

    }
}



.rn-collection-inner-one {
    .collection-wrapper {
        position: relative;
        padding: 18px;
        background: var(--background-color-1);
        border-radius: 5px;
        .collection-big-thumbnail {
            overflow: hidden;
            border-radius: 5px;
            img {
                border-radius: 5px;
                object-fit: cover;
                width: 100%;
                height: auto;
                transition: var(--transition);
            }
        }
        .collenction-small-thumbnail {
            display: flex;
            justify-content: space-between;
            margin: -4px;
            margin-top: 4px;
            img {
                display: inline-block;
                width: 33.33%;
                padding: 4px;
                border-radius: 10px;
            }
        }

        .collection-profile {
            position: absolute;
            top: 48%;
            left: 50%;
            transform: translate(-50%, 0);

            @media #{$large-mobile} {
                top: 56%;
            }

            @media #{$small-mobile} {
                top: 53%;
            }

            img {
                max-width: 80px;
                height: 80px;
                border-radius: 50%;
                border: 5px solid var(--color-primary-alta);
                transition: var(--transition);

                @media #{$md-layout} {
                    max-width: 60px;
                    height: 60px;
                }

                @media #{$sm-layout} {
                    max-width: 60px;
                    height: 60px;
                }

                @media #{$large-mobile} {
                    max-width: 80px;
                    height: 80px;
                }
            }
        }

        .collection-deg {
            display: flex;
            margin-top: 15px;
            justify-content: space-between;
            align-items: center;

            h6 {
                margin: 0;
                font-weight: 400;
                transition: var(--transition);

                &:hover {
                    color: var(--color-primary);
                }
            }

            span {
                &.items {
                    padding: 5px 8px;
                    background: var(--color-primary-alta);
                    border-radius: 3px;
                    color: var(--color-white);
                    transition: var(--transition);

                    &:hover {
                        background: var(--color-primary) !important;
                        color: var(--color-white) !important;
                    }
                }
            }
        }

        &:hover {
            .collection-big-thumbnail {
                img {
                    transform: scale(1.1);
                }
            }
        }
    }
}

.creator-list-wrapper {
    counter-reset: rn-list-counter;
    .creator-single {
        counter-increment: rn-list-counter;
        position: relative;
        &::before {
            content: counter(rn-list-counter);
            font-weight: 700;
            font-size: 61px;
            margin-right: 0.5rem;
            line-height: 1;
            position: absolute;
            right: 30px;
            z-index: 3;
            color: var(--color-body);
            opacity: 0.1;
            top: 50%;
            transform: translateY(-50%);
        }
    }
}

.top-seller-list-wrapper {
    counter-reset: rn-list-counter;
    .top-seller-list {
        counter-increment: rn-list-counter;
        position: relative;
        &::before {
            content: counter(rn-list-counter);
            font-weight: 700;
            font-size: 61px;
            margin-right: 0.5rem;
            line-height: 1;
            position: absolute;
            right: 30px;
            z-index: 3;
            color: var(--color-body);
            opacity: 0.1;
            top: 50%;
            transform: translateY(-50%);
            transition: 0.4s;
            @media #{$small-mobile} {
                font-size: 40px;
                right: 0;
            }
        }
        &:hover {
            &::before {
                font-size: 45px;
            }
        }
    }
   
}


