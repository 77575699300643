/*---------------------------
 Masonary Item  
-----------------------------*/
.grid-metro3 {
    margin: 0 -15px;
    .grid-metro-item {
        width: 33.33%;
        padding: 0 15px;
        margin-top: 30px;
        @media #{$md-layout} {
            width: 50%;
        }
        @media #{$sm-layout} {
            width: 50%;
        }
        @media #{$large-mobile} {
            width: 100%;
        }
    }
    .resizer {
        width: 33.33%;
        @media #{$md-layout} {
            width: 50%;
        }
        @media #{$sm-layout} {
            width: 50%;
        }
        @media #{$large-mobile} {
            width: 100%;
        }
    }
}

.grid-metro4 {
    margin: 0 -15px;
    .grid-metro-item {
        width: 25%;
        padding: 0 15px;
        margin-top: 30px;
        @media #{$md-layout} {
            width: 50%;
        }
        @media #{$sm-layout} {
            width: 50%;
        }
        @media #{$large-mobile} {
            width: 100%;
        }
    }
    .resizer {
        width: 25%;
        @media #{$md-layout} {
            width: 50%;
        }
        @media #{$sm-layout} {
            width: 50%;
        }
        @media #{$large-mobile} {
            width: 100%;
        }
    }
}

.grid-metro5 {
    margin: 0 -15px;
    .grid-metro-item {
        width: 20%;
        padding: 0 15px;
        margin-top: 30px;
        @media #{$lg-layout} {
            width: 33.33%;
        }
        @media #{$md-layout} {
            width: 50%;
        }
        @media #{$sm-layout} {
            width: 50%;
        }
        @media #{$large-mobile} {
            width: 100%;
        }
    }
    .resizer {
        width: 20%;
        @media #{$lg-layout} {
            width: 33.33%;
        }
        @media #{$md-layout} {
            width: 50%;
        }
        @media #{$sm-layout} {
            width: 50%;
        }
        @media #{$large-mobile} {
            width: 100%;
        }
    }
}


.sidebar-header {
    .grid-metro4 {
        .grid-metro-item {
            @media #{$lg-layout} {
                width: 50%;
            }
        }
        .resizer {
            @media #{$lg-layout} {
                width: 50%;
            }
        }
    }
}



.nft-left-sidebar-nav {
    .grid-metro4 {
        .grid-metro-item {
            @media #{$laptop-device} {
                width: 33.33%;
            }
            
        }
        .resizer {
            @media #{$laptop-device} {
                width: 33.33%;
            }
          
        }
    }
}






