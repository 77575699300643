@import url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css");

/* ---------------Animation---------------- */

.slit-in-vertical {
	-webkit-animation: slit-in-vertical 0.45s ease-out both;
	        animation: slit-in-vertical 0.45s ease-out both;
}

@-webkit-keyframes slit-in-vertical {
  0% {
    -webkit-transform: translateZ(-800px) rotateY(90deg);
            transform: translateZ(-800px) rotateY(90deg);
    opacity: 0;
  }
  54% {
    -webkit-transform: translateZ(-160px) rotateY(87deg);
            transform: translateZ(-160px) rotateY(87deg);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateZ(0) rotateY(0);
            transform: translateZ(0) rotateY(0);
  }
}
@keyframes slit-in-vertical {
  0% {
    -webkit-transform: translateZ(-800px) rotateY(90deg);
            transform: translateZ(-800px) rotateY(90deg);
    opacity: 0;
  }
  54% {
    -webkit-transform: translateZ(-160px) rotateY(87deg);
            transform: translateZ(-160px) rotateY(87deg);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateZ(0) rotateY(0);
            transform: translateZ(0) rotateY(0);
  }
}

/*---------------#region Alert--------------- */

#dialogoverlay{
  display: none;
  opacity: .8;
  position: fixed;
  top: 0px;
  left: 0px;
  background: transparent;
  width: 100%;
  z-index: 10;
}

#dialogbox{
  display: none;
  position: absolute;
  background: var(--background-color-4);
  border-radius:7px; 
  box-shadow: 0 4px 8px 0 #000;
  transition: 0.3s;
  width: 40%;
  z-index: 10;
  top:0;
  left: 0;
  right: 0;
  margin: auto;
  @media #{$md-layout} {
    width: 70%;
  }
  @media #{$sm-layout} {
    width: 80%;
  }
  @media #{$sm-layout} {
    width: 80%;
  }
}

#dialogbox:hover {
  box-shadow: 0 8px 16px 0 #000;
}

.container {
  padding: 2px 16px;
}

.pure-material-button-contained {
  position: relative;
  display: inline-block;
  box-sizing: border-box;
  border: none;
  border-radius: 4px;
  padding: 0 16px;
  max-width: 64px;
  height: 36px;
  vertical-align: middle;
  text-align: center;
  text-overflow: ellipsis;
  text-transform: uppercase;
  color:rgb(255, 255, 255);
  background-color:var(--color-primary);
  /* background-color: rgb(1, 47, 61) */
  box-shadow: 0 3px 1px -2px #000, 0 2px 2px 0 #000, 0 1px 5px 0 #000;
  font-family: var(--pure-material-font, "Roboto", "Segoe UI", BlinkMacSystemFont, system-ui, -apple-system);
  font-size: 14px;
  font-weight: 500;
  line-height: 36px;
  overflow: hidden;
  outline: none;
  cursor: pointer;
  transition: box-shadow 0.2s;
}

.pure-material-button-contained::-moz-focus-inner {
  border: none;
}

/* ---------------Overlay--------------- */

.pure-material-button-contained::before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #000;
  opacity: 0;
  transition: opacity 0.2s;
}

/* Ripple */
.pure-material-button-contained::after {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  border-radius: 50%;
  padding: 50%;
  width: 32px; /* Safari */
  height: 32px; /* Safari */
  background-color: #000;
  opacity: 0;
  transform: translate(-50%, -50%) scale(1);
  transition: opacity 1s, transform 0.5s;
}

/* Hover, Focus */
.pure-material-button-contained:hover,
.pure-material-button-contained:focus {
  box-shadow: 0 2px 4px -1px #000, 0 4px 5px 0 #000, 0 1px 10px 0 #000;
}

.pure-material-button-contained:hover::before {
  opacity: 0.08;
}

.pure-material-button-contained:focus::before {
  opacity: 0.24;
}

.pure-material-button-contained:hover:focus::before {
  opacity: 0.3;
}

/* Active */
.pure-material-button-contained:active {
  box-shadow: 0 5px 5px -3px #000, 0 8px 10px 1px #000, 0 3px 14px 2px #000;
}

.pure-material-button-contained:active::after {
  opacity: 0.32;
  transform: translate(-50%, -50%) scale(0);
  transition: transform 0s;
}

/* Disabled */
.pure-material-button-contained:disabled {
  color: #000;
  background-color: #000;
  box-shadow: none;
  cursor: initial;
}

.pure-material-button-contained:disabled::before {
  opacity: 0;
}

.pure-material-button-contained:disabled::after {
  opacity: 0;
}

#dialogbox > div{ 
  background:var(--background-color-4); 
  margin:8px; 
}

#dialogbox > div > #dialogboxhead{ 
  background: #000; 
  font-size:19px; 
  padding:10px; 
  color:rgb(255, 255, 255); 
  font-family: Verdana, Geneva, Tahoma, sans-serif ;
}

#dialogbox > div > #dialogboxbody{ 
  background:var(--color-success); 
  padding:20px; 
  color:var(--color-white) !important; 
  font-size: 16px;
  border-radius: 5px;
}

#dialogbox > div > #dialogboxfoot{ 
  background: var(--background-color-4); 
  padding:10px; 
  text-align:right; 
}
/*#endregion Alert*/