/*-------------------------------
    Slick Arrow And Dots Styles  
----------------------------------*/
button {
    &.slide-arrow {
        height: 50px;
        width: 50px;
        border-radius: 6px;
        position: absolute;
        z-index: 2;
        opacity: 0;
        transition: .3s;
        color: var(--color-white);
        background: #1b1b27;
        border: none;
        transform: translateY(-50%) scale(1);
        top: 50%;

        @media #{$lg-layout} {
            opacity: 1;
        }

        @media #{$md-layout} {
            opacity: 1;
        }

        @media #{$sm-layout} {
            opacity: 1;
        }

        i {
            font-size: 22px;
            color: var(--color-primary);
        }

        &.slide-arrow {
            top: -30px !important;
            opacity: 1;
            border-radius: 6px;
        }

        &.prev-arrow {
            right: 92px !important;;
        }

        &.next-arrow {
            right: 22px !important;
        }

        &:hover {
            background: var(--color-primary) !important;
            &.slide-arrow {
                i {
                    color: var(--color-white) !important;
                }
            }
        }
    }
}



.slick-arrow-between {
    position: relative;
    button {
        &.slide-arrow {
            height: 58px;
            width: 58px;
            border-radius: 50%;

            &.prev-arrow {
                top: 50% !important;
                left: 40px !important;
            }

            &.next-arrow {
                top: 50% !important;
                right: 40px !important;
            }
        }
    }
}

.slick-dots {
    position: relative;
    margin-top: -5px;
    li {
        &.slick-active {
            background: transparent;
            button {
                background: transparent !important;
                border: 2px solid var(--color-primary);
            }
        }
        button {
            font-size: 0;
            line-height: 0;
            display: block;
            width: 7px;
            height: 7px;
            padding: 5px;
            cursor: pointer;
            color: transparent;
            border: 0;
            outline: none;
            background: var(--color-primary-alta);
            border-radius: 50%;
        
            &::before {
                display: none;
            }
        }
    }
}


