/*--------------------------
    Breadcrumb Styles 
----------------------------*/

.rn-breadcrumb-inner {
    border-bottom: 1px solid var(--color-border);
    .title {
        margin-bottom: 0;
    }
    .breadcrumb-list {
        list-style: none;
        padding: 0;
        margin: 0;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        @media #{$sm-layout} {
            justify-content: center;
        }
        li {
            a {
                color: var(--color-body);
                transition: 0.4s;
                &:hover {
                    color: var(--color-white);
                }
            }
            &.separator {
                margin: 0 15px;
            }
            &.current {
                color: var(--color-white);
            }
        }
    }
}















