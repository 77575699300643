// tab button area


// product-details

.product-tab-wrapper {
    position: sticky;
    .pd-tab-inner {
        display: flex;
    }

    .rn-pd-nav {
        max-width: 100%;
        display: flex;
        justify-content: space-between;
        flex-basis: 25%;
        flex-direction: column;
        margin: -10px;
        padding-right: 20px;
        @media #{$md-layout} {
            margin: -10px 0;
        }
        @media #{$sm-layout} {
            margin: -10px 0;
        }
    }

    .rn-pd-content {
        max-width: 100%;
        height: auto;
        flex-basis: 75%;
        padding-left: 20px;

        .tab-pane {
            height: 100%;
        }

        .rn-pd-thumbnail {
            padding: 15px;
            background: var(--background-color-1);
            border-radius: 7px;
            border: 2px solid transparent;
            @media #{$large-mobile} {
                padding: 5px;
            }

            img {
                border-radius: 7px;
                object-fit: cover;
                width: -webkit-fill-available;
            }
        }
    }

    .rn-pd-rt-content {
        button {
            border-radius: 5px;
            transition: var(--transition);
            height: auto;
            width: 100%;
            margin: 10px;
            &.nav-link {
                background: none;
                padding: 0;
                &.active {
                    .rn-pd-sm-thumbnail {
                        border-color: var(--color-border);
                    }

                }
            }

            .rn-pd-sm-thumbnail {
                padding: 7px;
                background: var(--background-color-1);
                border-radius: 5px;
                border: 2px solid transparent;
                display: block;

                @media #{$large-mobile} {
                    padding: 5px;
                }

                img {
                    border-radius: 5px;
                    height: auto;
                    width: 100%;
                }
            }
        }
    }

   
}


.rn-pd-sm-slick {
    h6 {
        &.title {
            margin-bottom: 0;
            font-weight: 400;
        }
    }

    .pd-slide-wrapper {
        margin: 0 -10px;
        margin-top: 20px;

        .slick-list {
            .slick-track {
                margin-top: 0;
            }
        }

        button {
            &.slide-arrow {
                border: none;
                padding: 15px;
                height: 36px;
                width: 36px;
                border-radius: 5px;
                background: var(--color-primary-alta);
                display: flex;
                justify-content: center;
                align-items: center;
                top: -50px;
                right: 10px;
                position: absolute;
                color: var(--color-white);
                transition: var(--transition);

                &:hover {
                    background: var(--color-primary);
                }
            }

            &.prev-arrow {
                right: 65px;
            }
        }

        .rn-pd-slick {
            padding: 15px;
            background: var(--background-color-1);
            margin: 0 15px;
            border-radius: 6px;

            @media #{$large-mobile} {
                padding: 5px;
            }

            .rn-pd-small-thumb {
                img {
                    border-radius: 5px;
                    width: 100%;
                    height: auto;
                }
            }

            .rn-pd-pric {
                margin-top: 10px;
                display: flex;
                justify-content: space-between;

                span {
                    color: var(--color-body);
                    font-weight: 500;
                }
            }
        }

        .slick-list {
            margin: 0px -5px;
        }
    }
}

.rn-pd-content-area {
    span {
        &.bid {
            font-weight: 500;
            font-size: 14px;

            span {
                &.price {
                    color: var(--color-primary);
                }
            }
        }
    }

    h6 {
        &.title-name {
            font-size: 20px;
            font-weight: 500;
            margin-top: 15px;
            margin-bottom: 30px;
        }
    }

    .catagory-collection {
        display: flex;
        margin-bottom: 20px;

        .top-seller-wrapper {
            .thumbnail {
                max-width: 50px;
            }
        }

        .catagory {
            margin-right: 50px;

            span {
                color: var(--color-white);
                font-size: 16px;

                span {
                    &.color-body {
                        font-weight: 500;
                    }
                }
            }
        }

        .collection {
            @media #{$small-mobile} {
                text-align: center;
            }

            span {
                color: var(--color-white);

                span {
                    font-size: 16px;

                    &.color-body {
                        font-weight: 500;
                    }
                }
            }
        }

        .top-seller-inner-one {
            margin-top: 5px;
        }
    }

    // bid details
    .pd-title-area {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 10px;
        @media #{$small-mobile} {
            display: block;
        }
        h4 {
            &.title {
                font-size: 30px;
                margin-bottom: 0;
                @media #{$small-mobile} {
                    margin-bottom: 15px;
                }
            }
        }
        .pd-react-area {
            display: flex;
            @media #{$small-mobile} {
                margin-bottom: 15px;
            }
            .heart-count {
                padding: 7px 12px;
                background: var(--color-primary-alta);
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;
                border-radius: 6px;
                transition: var(--transition);

                svg {
                    width: 18px;
                    margin-right: 5px;
                    transition: var(--transition);
                }

                span {
                    color: var(--color-white);
                    transition: var(--transition);
                }

                &:hover {
                    background: var(--color-primary) !important;

                    svg {
                        color: var(--color-white) !important;
                    }

                    span {
                        color: var(--color-white) !important;
                    }
                }
            }

            .count {
                padding: 7px 7px;
                background: var(--color-primary-alta);
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;
                border-radius: 6px;
                margin-left: 15px;
                transition: var(--transition);

                button {
                    border: 0 none;
                }

                svg {
                    width: 18px;
                    transition: var(--transition);
                }

                &:hover {
                    background: var(--color-primary) !important;

                    svg,
                    span {
                        color: var(--color-white) !important;
                    }
                }
            }
        }
    }
}

.rn-bid-details {
    margin-top: 40px;

    .rn-bid-content {
        display: block;

        .top-seller-inner-one {
            padding-top: 20px;
            
            & + .top-seller-inner-one {
                margin-top: 20px;
                border-top: 1px solid var(--color-border);
            }
        }
    }

    .countdown {
        .countdown-container {
            .countdown-value {
                background-color: var(--color-primary-alta);
                backdrop-filter: blur(15px);
            }
        }
    }
}

.tab-wrapper-one {
    margin-bottom: 30px;

    .top-seller-inner-one {
        .thumbnail {
            max-width: 50px;
        }
    }
}

.tab-button-one {
    display: block;
    border-radius: 5px;

    .nav-tabs {
        border-bottom: none;
        display: flex;
        flex-wrap: wrap;
        transition: var(--transition);
        background: var(--background-color-1);
        border: 1px solid var(--color-border);
        border-radius: 6px;
        button {
            width: 150px;
            height: 50px;
            border: none;
            color: var(--color-white);
            position: relative;
            margin: 6px;
            border-radius: 6px;
            border: 1px dashed var(--color-border);
            transition: 0.3s;
            @media #{$small-mobile} {
                width: 100%;
            }
            &.active {
                background: var(--color-primary-alta);
                color: var(--color-white);
                border-radius: 5px;
                border: transparent;
            }
            @media #{$laptop-device} {
                width: 130px;
            }
           
        }
    }
}


.place-bet-area {
    background: var(--background-color-1);
    padding: 30px;
    border-radius: 6px;
    height: auto;
    width: 100%;
    border: 1px solid var(--color-border);

    .rn-bet-create {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        margin: -15px;

        .bid-list {
            padding: 15px;
        }
        @media #{$large-mobile} {
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
            flex-direction: column;
            justify-content: center;
            transform: translateX(25%);
            text-align: center;
        }

        @media #{$small-mobile} {
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
            flex-direction: column;
            justify-content: center;
            transform: translateX(14%);
            text-align: center;
        }
    }

    .winning-bid {
        flex-basis: 60%;

        h6 {
            font-weight: 400;
            font-size: 18px;
        }

        .thumbnail {
            max-width: 50px;

            a {
                img {
                    border-radius: 50%;
                }
            }
        }

        .top-seller-content {
            .heighest-bid {
                font-size: 16px;
            }

            .count-number {
                font-size: 14px;
            }
        }
    }

    .left-bid {
        flex-basis: 40%;

        .countdown {
            justify-content: flex-start;
        }

        h6 {
            font-weight: 400;
            font-size: 18px;
            margin-bottom: 0;
        }

        @media #{$small-mobile} {
            margin-top: 20px;
        }
    }

    .btn {
        display: block;
        bottom: 30px;
        width: 100%;
        margin: auto;
        padding: 15px;
    }
}



.rn-pd-bd-wrapper {
    max-width: 450px;
    .top-seller-inner-one {
        p {
            margin-bottom: 7px;
            font-size: 16px;
        }

        h6 {
            &.name-title {
                font-size: 14px;
                font-weight: 500;
                margin-bottom: 10px;
                color: var(--color-body);

                @media #{$small-mobile} {
                    text-align: center;
                }
            }
        }
    }
}

.rn-pd-sm-property-wrapper {
    margin-top: 30px;
    h6 {
        &.pd-property-title {
            font-size: 14px;
            font-weight: 500;
            color: var(--color-body);
            margin-bottom: 10px;
        }
    }

    .property-wrapper,
    .catagory-wrapper {
        display: flex;
        flex-wrap: wrap;
        margin: -7px;
        .pd-property-inner {
            padding: 10px 15px;
            background: var(--background-color-1);
            border-radius: 6px;
            max-width: 200px;
            margin: 7px;
            border: 1px solid var(--color-border);
            backdrop-filter: blur(15px);
            span {
                display: block;
                font-size: 18px;
                margin-bottom: 7px;
                &:last-child {
                    margin: 0;
                }
                &.type {
                    font-size: 12px;
                }
                &.value {
                    font-size: 14px;
                    letter-spacing: 0.5px;
                }
            }
        }
    }
}
