/*--------------------------
    Copyright Area  
----------------------------*/
.copy-right-one {
    border-top: 1px solid var(--color-border);
    
    .copyright-left {
        display: flex;

        @media #{$md-layout} {
            justify-content: center;
            margin-bottom: 15px;
        }
        @media #{$sm-layout} {
            display: block;
            text-align: center;
        }

        span {
            font-size: 16px;
            font-weight: 400;
            color: var(--color-body);
        }

        .privacy {
            display: flex;
            list-style: none;
            margin: 0;
            padding-left: 18px;
            position: relative;
            margin-left: 30px;
            @media #{$lg-layout} {
                padding-left: 7px;
                margin-left: 7px;
            }
            @media #{$md-layout} {
                padding-left: 7px;
                margin-left: 7px;
            }
            @media #{$sm-layout} {
                padding-left: 0;
                justify-content: center;
                margin: 10px 0;
            }
            &::before {
                position: absolute;
                content: "";
                left: 0;
                top: 50%;
                width: 1px;
                height: 25px;
                background: var(--color-border);
                transform: translateY(-50%);
                @media #{$sm-layout} {
                    display: none;
                }
            }
            li {
                margin: 0;
                padding: 0 12px;
                a {
                    position: relative;
                    color: var(--color-body);
                    font-size: 16px;
                    font-weight: 400;
                    &::after {
                        position: absolute;
                        content: "";
                        left: auto;
                        bottom: 0;
                        background: var(--color-primary);
                        width: 0;
                        height: 2px;
                        transition: .3s;
                        right: 0;
                    }
                    &:hover {
                        color: var(--color-primary) !important;
                        &::after {
                            width: 100%;
                            left: 0;
                            right: auto;
                        }
                    }
                }

                & + li {
                    position: relative;

                    &::before {
                        position: absolute;
                        content: '';
                        height: 3px;
                        width: 3px;
                        background: var(--color-body);
                        left: 0;
                        top: 50%;
                        transform: translateY(-50%);
                        border-radius: 50%;
                    }
                }
            }
        }
    }

    .copyright-right {
        margin: 0;
        display: flex;
        justify-content: flex-end;
        
        @media #{$md-layout} {
            margin: 0;
            padding: 0;
            justify-content: center;
        }

        @media #{$sm-layout} {
            margin: 0;
            padding: 0;
            justify-content: center;
        }

        .social-copyright {
            list-style: none;
            display: flex;
            margin: 0 -5px;
            padding: 0;
            li {
                margin: 0 5px;
                a {
                    color: var(--color-body);
                    transition: 0.4s;
                    background: var(--background-color-4);
                    display: inline-block;
                    width: 40px;
                    height: 40px;
                    line-height: 40px;
                    text-align: center;
                    border-radius: 100%;
                    
                    svg {
                        width: 16px;
                        transition: 0.4s;
                    }
                    
                    &:hover {
                        color: var(--color-white) !important;
                        background: var(--color-primary) !important;
                        svg {
                            color: var(--color-white);
                        }
                    }
                }
            }
        }
    }
}