// count down
.countdown {
    display: flex;
    margin: -4px;
    justify-content: center;

    .countdown-container {
        margin: 4px !important;
        position: relative;

        .countdown-heading {
            display: block;
            color: var(--color-body);
            font-size: 14px;
            text-align: center;
            text-transform: uppercase;
            margin-top: 0;
            display: block;
        }

        .countdown-value {
            display: block;
            font-size: 20px;
            color: var(--color-white);
            font-weight: 600;
            text-align: center;
            border-radius: 4px;
            padding: 2px 10px;
            position: relative;
            background-color: rgba(36, 36, 53, 0.3);
            backdrop-filter: blur(15px);

            &:after {
                content: ":";
                top: 50%;
                transform: translateY(-50%);
                right: -6px;
                position: absolute;
                font-size: 20px;
                color: var(--color-body);
                font-weight: 400;
            }

        }


        &:last-child {
            .countdown-value {
                &::after {
                    display: none;
                }
            }
        }

    }
}